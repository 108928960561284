import React from "react";

import HomePage from "./HomePage";

export const Home = () => {
    return (
        <div>
            <HomePage />
        </div>
    );
};
