/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable sort-keys */
import React from "react";
import { Link } from "react-router-dom";
import BackgroundImage from "../../../assets/images/pages-background-shape-2.png";
//import Product1 from "../../../assets/images/product-list-1.jpg";
import Product2 from "../../../assets/images/product-list-2.jpg";
import { AppLayout } from "../../../components";
import { useAuth } from "../../../hooks";
import { formatMoney } from "../../../utils";

export const ShoppingCart = () => {
    const  { getCart ,removeFromCartFunc,addToCartFunc,removeAllCartFunc } =useAuth();
    //console.log(getCart,"getCart");

    const handleRemoveCart = (id,cartid) => {
        removeFromCartFunc(id,cartid);
    };
    const handleAddCart = (id,cartid) => {
        addToCartFunc(id,cartid);
    };
    const handleRemoveAllCart = (cartid)=>{
        //console.log(cartid);
        removeAllCartFunc(cartid);
    };

    return (
        <AppLayout
            mode="light"
            background={`url(${BackgroundImage}) no-repeat center center /cover`}
        >
            <div className="main-content pt-2 pt-md-5 pb-2 pb-md-5">
                <div className="product-shipping">
                    <div className="container">
                        <div className="w-100 mb-4 border-bottom pb-3 shipping-heading">
                            <div className="row">
                                <div className="col-12 col-sm-6 mb-3">
                                    <h4 className="pt-2">Shopping Cart</h4>
                                </div>
                                <div className="col-12 col-sm-6 shipping-processes text-left text-md-right mb-3">
                                    <span className="shipping-cart pl-1 pl-md-5">
                                        <i className="bi bi-cart3 active" />
                                    </span>
                                    <span className="shipping-truck pl-5">
                                        <i className="bi bi-truck" />
                                    </span>
                                    <span className="shipping-credit-card pl-5">
                                        <i className="bi bi-credit-card" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="w-100 mb-5 single-product-shipping-wrap">
                            <div className="w-100 border-bottom pb-3 mb-4 text-uppercase single-product-shipping-headings">
                                <div className="row">
                                    <div className="col-3 col-md-6">
                                        <h6 className="pl-4">Item</h6>
                                    </div>
                                    <div className="col-9 col-md-6">
                                        <div className="d-flex">
                                            <div className="flex-fill">
                                                <h6>Price</h6>
                                            </div>
                                            <div className="flex-fill">
                                                <h6>Quantity</h6>
                                            </div>
                                            <div className="flex-fill">
                                                <h6 className="pl-md-5">Total</h6>
                                            </div>
                                            <div className="flex-fill">
                                                <h6>Remove</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {getCart?.cart?.length<1 &&<div className="text-center py-5">
                                <h4> Hi, You have nothing in your cart</h4> 
                            </div>} 
                            
                            {getCart?.cart?.map((item,index)=><span key={index}>
                                <div className="w-100 border-bottom pb-3 mb-4 single-product-shipping">
                                    <div className="row">
                                   
                                        <div className="col-12 col-sm-6 d-inline">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <img
                                                        alt=""
                                                        className="item-image"
                                                        src={Product2}
                                                    />{" "}
                                                </div>
                                                <div>
                                                    <h6 className="d-inline">
                                                        {item?.product?.productName}
                                                    </h6>
                                                </div>
                                            </div>
                                            
                                          
                                        </div>
                                        <div className="col-12 col-sm-6 pt-2 d-flex">
                                            <span
                                                className="flex-fill pt-2 single-product-amount"
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 600
                                                }}
                                            >
                                                {" "}
                                                ₦ {formatMoney(item?.product?.costPerUnit)}
                                            </span>
                                            <span className="flex-fill single-product-quanitity-amount">
                                                <div className="cart_quantity_button">
                                                    <span
                                                        className="icon cart_quantity_up"
                                                        role="button"
                                                        
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 600,
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={()=>handleRemoveCart(item?.product?._id,getCart?.cartid)}
                                                      
                                                    >
                                                        {" "}
                                                        -{" "}
                                                    </span>
                                                    <input
                                                        className="cart_quantity_input ml-3 mr-3"
                                                        type="text"
                                                        name="quantity"
                                                        value={item?.quantity?item?.quantity:1}
                                                        autoComplete="off"
                                                        onChange={()=>{}}
                                                        // size={2}
                                                    />
                                                    <span
                                                        className="icon cart_quantity_down"
                                                        role="button"
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 600,
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={()=>handleAddCart(item?.product?._id,getCart?.cartid)}
                                                    >
                                                        {" "}
                                                        +{" "}
                                                    </span>
                                                </div>
                                            </span>
                                            <span
                                                className="flex-fill pt-2 single-product-quanitity-total"
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 600
                                                }}
                                            >
                                                {" "}
                                                ₦ {formatMoney(item?.price)}
                                            </span>
                                            <span
                                                className="flex-fill pt-2 remove-product"
                                                onClick={()=>handleRemoveAllCart("61f938c7f27c9c440511d1f1")}
                                                aria-hidden="true"
                                                style={{
                                                    fontSize: 14,
                                                    fontWeight: 600,
                                                    cursor: "pointer"
                                                }}
                                               
                                            >
                                                <i className="bi bi-x-circle" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </span>)}
                          
                        </div>
                        <div className="w-100 shipping-btn pt-5">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-3 mb-3 text-center text-md-left">
                                        <div className="promo-code">
                                            <Link
                                                to=""
                                                className="btn btn-lg btn-outline-secondary text-capitalize text-muted"
                                                role="button"
                                                aria-pressed="true"
                                                style={{
                                                    borderRadius: 50,
                                                    fontSize: 16
                                                }}
                                            >
                                                Promo Code{" "}
                                                <i className="pl-5 bi bi-cursor-fill" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3  mb-3 text-center text-capitalize text-dark">
                                        <div className="pt-2 total-cost-value">
                                            <h5 className="d-inline font-weight-bold">
                                                {" "}
                                                <span className="pr-2">
                                                    Total Cost
                                                </span>{" "}
                                                { getCart?.cartdetails?.cart?.toBePaid ? ` ₦ ${formatMoney(getCart?.cartdetails?.cart?.toBePaid)}` : "₦ 0"}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 mb-3 text-center text-md-right">
                                        <div className="continue-shopping">
                                            <Link
                                                to="/products/list"
                                                className="btn btn-lg btn-outline-secondary text-uppercase text-muted"
                                                role="button"
                                                aria-pressed="true"
                                                style={{
                                                    borderRadius: 50,
                                                    fontSize: 16
                                                }}
                                            >
                                                continue shopping
                                            </Link>
                                        </div>
                                    </div>
                                    {getCart?.cart?.length>0 &&   <div className="col-12 col-md-3 text-center text-md-right">
                                       
                                        <div className="processed-shipping">
                                            <Link
                                                to="/shipping"
                                                className="btn btn-lg text-uppercase text-white"
                                                role="button"
                                                aria-pressed="true"
                                                style={{
                                                    backgroundColor: "#463c74",
                                                    borderRadius: 50,
                                                    fontSize: 16
                                                }}
                                            >
                                                proceed to shipping
                                            </Link>
                                        </div>
                                       
                                    </div> }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};
