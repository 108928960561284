import { GiSwapBag } from "react-icons/gi";
import { BsCart3 } from "react-icons/bs";
import { FiTrendingUp } from "react-icons/fi";
import Product1 from "../assets/images/product-1.png";
import defaultLogo1 from "../assets/images/logo.jpg";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";

export const refererHeaderData = [
    {
        name: "Name"
    },
    {
        name: "location"
    },
    {
        name: "Views"
    },
    {
        name: "Sales"
    },
    {
        name: "Conversion"
    },
    {
        name: "Total"
    }
];

export const mainreferenceData = [
    {
        name: "Joshua",
        location: "Lekki",
        views: "375",
        sales: "752",
        conversion: "43%",
        total: "NGN 19,291"
    },
    {
        name: "Adeyemi",
        location: "Egbeda",
        views: "375",
        sales: "752",
        conversion: "43%",
        total: "NGN 19,291"
    },
    {
        name: "Chike",
        location: "VI",
        views: "375",
        sales: "752",
        conversion: "43%",
        total: "NGN 19,291"
    }
];

export const ProductData = [
    {
        icon: <GiSwapBag className="h5 color-2 mb-0" />,
        sales: "100,000",
        title: "Total Revenue"
    },
    {
        icon: <BsCart3 className="h5 color-2 mb-0" />,
        sales: "3,000",
        title: "Complete Sales"
    },
    {
        icon: <FiTrendingUp className="h5 text-success  mb-0" />,
        sales: "+2.0%",
        title: "Growth",
        gain: true
    }
];

export const topProductHeader = [
    {
        name: "PRODUCT NAME"
    },

    {
        name: "Product SKU"
    },
    {
        name: "AVAILABILITY"
    },
    {
        name: "TOTAL"
    }
];

export const topAllProductHeader = [
    {
        name: "#"
    },
    {
        name: "Product Name"
    },
    {
        name: "Category"
    },
    {
        name: "Product SKU"
    },
    {
        name: "Expiring date "
    },
    {
        name: "Price"
    },
    {
        name: "Image"
    },
    {
        name: "Action"
    }
];
export const topProductData = [
    {
        product: "Augmentin",
        image: Product1,
        sku: "GTY123QQ",
        inStock: "320",
        total: "29,000"
    },
    {
        product: "Augmentin",
        image: Product1,
        sku: "GTY123QQ",
        inStock: "3",
        total: "29,000"
    },
    {
        product: "Augmentin",
        image: Product1,
        sku: "GTY123QQ",
        inStock: "0",
        total: "29,000"
    },
    {
        product: "Augmentin",
        image: Product1,
        sku: "GTY123QQ",
        inStock: "320",
        total: "29,000"
    }
];

export const refererDataFunc = (data) => {
    return data.map((item, index) => (
        <tr key={index}>
            <td>{item?.location}</td>
            <td>{item?.views}</td>
            <td>{item?.sales}</td>
            <td>{item?.conversion}</td>
            <td>{item?.total}</td>
        </tr>
    ));
};

export const checkStock = (stock) => {
    if (stock < 1) {
        return "bg-danger";
    }
    if (stock > 100) {
        return "bg-success";
    }
    if (stock < 100) {
        return "bg-warning";
    }
};

export const OrderHeader = [
    {
        name: "#"
    },
    {
        name: "Company/Client"
    },
    {
        name: "Order ID"
    },
    {
        name: "Order Date"
    },
    {
        name: "Order State"
    },
    {
        name: "Action"
    }
    // {
    //     name:'Client Status',
    // },
];

export const CustomerHeader = [
    {
        name: "#"
    },
    {
        name: "Name"
    },
    {
        name: "Creates at"
    },
    {
        name: "Location"
    },
    {
        name: "Status"
    },
    {
        name: "Rating"
    },
    {
        name: "Action"
    }
    // {
    //     name:'Client Status',
    // },
];
export const CustomerOrderHeader = [
    {
        name: "#"
    },

    {
        name: "Time"
    },
    {
        name: "Tracking_Id"
    },
    {
        name: "Price"
    },
    {
        name: "Status"
    },
    {
        name: "Action"
    }
    // {
    //     name:'Client Status',
    // },
];
export const ProductHeader = [
    {
        name: "#"
    },

    {
        name: "Product Name"
    },
    {
        name: "Product Sku"
    },
    {
        name: "Product Composition"
    },
    {
        name: "Price"
    },
    {
        name: "Quantity Bought"
    }

    // {
    //     name:'Client Status',
    // },
];

export const CustomerData = [
    {
        name: "Bola Pharmacy",
        createdat: "Feb 5th 2022",
        location: "lagos",
        status: "active",
        rating: "-"
    },
    {
        name: "Divine Pharmacy",
        createdat: "Apr 3th 2022",
        location: "lagos",
        status: "active",
        rating: "-"
    }
];

export const OrderData = [
    {
        company: "Lagos Pharmacy",
        orderid: "#123456",
        orderdate: "March 15,2021",
        orderState: "pending",
        status: 1
    },
    {
        company: "Lagos Pharmacy",
        orderid: "#123456",
        orderdate: "March 15,2021",
        orderState: "pending",
        status: 2
    },
    {
        company: "Lagos Pharmacy",
        orderid: "#123456",
        orderdate: "March 15,2021",
        orderState: "pending",
        status: 3
    }
];

export const InvoiceHeader = [
    {
        name: <span className="fw-1 text-muted">Item Name</span>
    },
    {
        name: <span className="fw-1 text-muted">Quantity</span>
    },
    {
        name: <span className="fw-1 text-muted">Stock Availability</span>
    }
];

export const InvoiceData = [
    {
        itemName: "Augmentin",
        quantity: "2 packet",
        stock: "20 Packet"
    }
];

export const InvoiceHeaderData = [
    {
        name: <span className="fw-1 text-muted">Item </span>
    },
    {
        name: <span className="fw-1 text-muted">Description</span>
    },
    {
        name: <span className="fw-1 text-muted">Quantity</span>
    },
    {
        name: <span className="fw-1 text-muted">Price</span>
    }
];

export const InvoiceDataDumy = [
    {
        item: "Augmentin",
        desc: "Lorem ipsum dolor sit amet Consecteur",
        quantity: "20",
        price: "NGN 3000"
    }
];

export const defaultLogo = defaultLogo1;

export const handleSuplierlink = (state) => {
    copy(
        `http://app.pharmaserv.ng/supplier/products/${state?.data?.supplierInformation[0]?._id}`
    );
    toast.success("link copy successfully...");
};
