import { PageNotFound } from "../components";
import {
    ForgotPassword,
    Home,
    Login,
    OrderShowAll,
    ProductDetails,
    ProductLayout,
    Invoice,
    AddProduct,
    Register,
    SelectAccountType,
    ShoppingCart,
    SupplierProduct,
    VerifyAccount,
    VerifyPayment,
    ProductShowAll,
    Customer,
    Myprofile,
    Companyprofile,
    HelpCenter,
} from "../pages";

export const routeList = [
    {
        children: [
            {
                component: Home,
                exact: true,
                name: "Home",
                path: "/",
                protected: true
            }
        ],
        component: Home,
        exact: true,
        name: "Home",
        // path: "/",
        protected: true
    },
    {
        component: Login,
        exact: true,
        name: "Login",
        path: "/login",
        protected: false
    },
    {
        children: [],
        component: VerifyAccount,
        exact: true,
        name: "Forgot Password",
        path: "/verify-account",
        protected: false
    },
    {
        component: Register,
        exact: true,
        name: "Register",
        path: "/register",
        protected: false
    },
    {
        component: ForgotPassword,
        exact: true,
        name: "Forgot Password",
        path: "/forgot-password",
        protected: false
    },
    {
        children: [
            {
                component: Customer,
                exact: true,
                name: "Customer",
                path: "customer",
                protected: true
            }
        ],
        component: Customer,
        exact: true,
        name: "Customer",
        path: "/",
        protected: true
    },

    {
        children: [
            {
                component: VerifyPayment,
                exact: true,
                name: "Forgot Password",
                path: "/verifypayment",
                protected: true
            }
        ],
        component: VerifyPayment,
        exact: true,
        name: "Forgot Password",
        path: "/verifypayment",
        protected: true
    },
    {
        children: [
            {
                component: HelpCenter,
                exact: true,
                name: "Help Center",
                path: "/help-center",
                protected: true
            }
        ],

        exact: true,
        name: "Help Center",
        path: "/",
        protected: true
    },
    {
        children: [
            {
                component: SelectAccountType,
                exact: true,
                name: "Choose Account type",
                path: "/choose-account",
                protected: true
            }
        ],
        component: SelectAccountType,
        exact: true,
        name: "Choose Account type",
        // path: "/choose-account",
        protected: true
    },
    {
        children: [
            {
                component: ProductShowAll,
                name: "Add Product ",
                path: "/product/all"
            },
            {
                component: AddProduct,
                name: "Add Product ",
                path: "/product/addproduct"
            },
            {
                component: ProductDetails,
                name: "Product Details",
                path: "product"
            }
        ],
        component: ProductLayout,
        name: "Products",
        path: "/",
        protected: true
    },
    {
        children: [
            {
                component: Companyprofile,
                name: "Company Profile ",
                path: "/settings/supplier"
            },
            {
                component: Myprofile,
                name: "Add Product ",
                path: "/settings/myprofile"
            }
        ],
        component: Myprofile,
        name: "Setting",
        // path: "/",
        protected: true
    },
    {
        children: [
            {
                component: SupplierProduct,
                name: "Supplier",
                path: "/suppliers/:supplier_id",
                protected: true
            }
        ],
        component: SupplierProduct,
        name: "Supplier",
        // path: "/suppliers/:supplier_id",
        protected: true
    },
    {
        children: [
            {
                component: ShoppingCart,
                name: "Shopping Cart",
                path: "cart"
            }
        ],
        component: ShoppingCart,
        exact: true,
        name: "Shopping Cart",
        protected: true
    },

    {
        children: [
            {
                component: OrderShowAll,
                exact: true,
                name: "Order Summary",
                path: "/order/all",
                protected: true
            }
        ],

        exact: true,
        name: "Order Summary",
        // path: "/summary",
        protected: true
    },
    {
        children: [
            {
                component: Invoice,
                exact: true,
                name: "Invoice",
                path: "/invoice/:id",
                protected: true
            }
        ],

        exact: true,
        name: "Invoice",
        // path: "/summary",
        protected: true
    },

    {
        component: PageNotFound,
        exact: true,
        name: "Page Not Found",
        path: "*",
        protected: false
    }
];
