import React from "react";

import { FiCircle } from "react-icons/fi";
import {
    HiOutlineArrowNarrowDown,
    HiOutlineArrowNarrowUp
} from "react-icons/hi";
import { RiUserAddLine } from "react-icons/ri";

//import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import { useQuery } from "react-query";
import {
    AppLayout,
    CardComp,
    SimpleLineChart,
    TopNav,
    TableComp
} from "../../components";
import { useAuth } from "../../hooks";
import Product1 from "../../assets/images/product-1.png";

import {
    mainreferenceData,
    refererHeaderData,
    topProductHeader,
    checkStock,
    handleSuplierlink
} from "../../utils/datautils";

import { Link } from "react-router-dom";
import { DashboardMetrics, getAllUserProduct } from "../../services";

//import { Link } from "react-router-dom";
// import { Header } from "../../components";

const Style = styled.div`
    .homepage {
        padding-top: 7rem;
    }
    .start-by-registering {
        padding: 14em 8em;
    }
    .card {
        border-radius: 15px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
        background-color: #fff;
    }
    .table {
        color: #4d4f5c;
    }
    .table thead th {
        font-size: 15px;
        font-weight: 500;
    }
    .Product td {
        height: 80px;
    }
    .productWrapper {
        width: 55px;
    }
    .iconwrapper {
        width: 35px;
        height: 35px;
        border: 1px solid #e8e7ff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 2px;
        margin-right: 10px;
        background: #e8e7ff;
    }

    @media (max-width: 767px) {
        .homepage {
            padding-top: 14rem;
        }
        .registering-cta {
            left: 0;
        }
    }
    @media (max-width: 480px) {
        .homepage {
            padding-top: 15rem;
        }
        .start-by-registering {
            padding: 12em 5em;
        }
    }
`;
const HomePage = () => {

    const {
        state,
        // state: { data,dashboardMertics,MapMertics },
       
       
    } = useAuth();

    const { data } = useQuery(
        "get-products",
        getAllUserProduct,
        {
            // refetchOnWindowFocus: true,
            refetchInterval: 2000
            // refetchIntervalInBackground: true,
        }
    );
    const { data:DashboardData } = useQuery(
        "dashboard-metric",
        DashboardMetrics,
        {
            // refetchOnWindowFocus: true,
            refetchInterval: 2000
            // refetchIntervalInBackground: true,
        }
    );
     //console.log(DashboardData,'data')

  

     //console.log(getUserProductList?.products,'MapMertics')
    const bodyData = () => {
        return data?.products?.slice(0, 3)?.map((item, index) => {
            return (
                <tr key={index}>
                    <td>
                        <div className="row align-items-center">
                            <div className="col-sm-3">
                                <div className="productWrapper ">
                                    <img src={Product1} alt="" />{" "}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <span>{item?.productName}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="d-flex align-items-center h-100">
                            {item?.productSku}
                        </span>
                    </td>
                    <td>
                        {" "}
                        <div className="d-flex  align-items-center h-100">
                            <span
                                className={`border px-2 py-1 rounded small  text-white text-center ${checkStock(
                                    200
                                )}`}
                            >
                                {200} In Stock
                            </span>
                        </div>
                    </td>
                    <td>
                        {" "}
                        <div className="d-flex align-items-center h-100">
                            <span className="">NGN {item?.costPerUnit}</span>
                        </div>
                    </td>
                </tr>
            );
        });
    };
    const topProductbodyData = () => {
        return mainreferenceData.map((item, index) => (
            <tr key={index}>
                <td>{item?.name}</td>
                <td>{item?.location}</td>
                <td>{item?.views}</td>
                <td>{item?.sales}</td>
                <td>{item?.conversion}</td>
                <td>{item?.total}</td>
            </tr>
        ));
    };

    // console.log(state,'state')
    return (
        <AppLayout mode="light">
            <Style>
                <div className="container-fluid mb-5">
                    <div className="mb-5">
                        <div className="d-flex justify-content-between align-items-center">
                            <h6 className="color-2 text-capitalize">
                                Welcome back, {state?.data?.firstName}
                            </h6>
                            <button
                                onClick={() => handleSuplierlink(state)}
                                className="btn bg-6 text-white font-weight-light  "
                            >
                                click to copy your Link
                            </button>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="row">
                            <div className="col-md-6 col-lg-4 mb-3">
                                <CardComp
                                    bodyText={
                                        <div>
                                            <TopNav
                                                TextComp={
                                                    <span className="fw-1">
                                                        Top View
                                                    </span>
                                                }
                                                DropDownText={
                                                    <span>
                                                        <span className=""></span>
                                                        Month
                                                    </span>
                                                }
                                            />
                                            <div>
                                                <div>
                                                    <div>
                                                        <h4>246K</h4>
                                                        <p className="d-flex align-items-center mb-0">
                                                            <span>
                                                                <HiOutlineArrowNarrowDown
                                                                    className="text-danger"
                                                                    size={18}
                                                                />
                                                            </span>
                                                            13.8%
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                            </div>
                            <div className="col-md-6 col-lg-4 mb-3">
                                <CardComp
                                    bodyText={
                                        <div>
                                            <TopNav
                                                TextComp={
                                                    <span className="fw-1 text-muted">
                                                        Proudct Sold
                                                    </span>
                                                }
                                                DropDownText={
                                                    <span>
                                                        <span className=""></span>
                                                        Month
                                                    </span>
                                                }
                                            />
                                            <div>
                                                <div>
                                                    <div>
                                                        {/* dashboardMertics:
totalAmountEarned: 0
totalProductsSold: 0 */}
                                                        <h4>
                                                            NGN{" "}
                                                            {
                                                                DashboardData?.data?.totalProductsSold
                                                            }
                                                        </h4>
                                                        <p className="d-flex align-items-center mb-0">
                                                            <span>
                                                                <HiOutlineArrowNarrowUp
                                                                    className="text-success"
                                                                    size={18}
                                                                />
                                                            </span>
                                                            13.8%
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                            </div>
                            <div className=" col-md-6 col-lg-4 mb-3">
                                <CardComp
                                    bodyText={
                                        <div>
                                            <TopNav
                                                TextComp={
                                                    <span className="fw-1 text-muted">
                                                        {" "}
                                                        Total Earnings
                                                    </span>
                                                }
                                                DropDownText={
                                                    <span>
                                                        <span className=""></span>
                                                        Month
                                                    </span>
                                                }
                                            />
                                            <div>
                                                <div>
                                                    <div>
                                                        <h4>
                                                            NGN{" "}
                                                            {
                                                                 DashboardData?.data?.totalAmountEarned
                                                            }
                                                        </h4>
                                                        <p className="d-flex align-items-center mb-0">
                                                            <span>
                                                                <HiOutlineArrowNarrowDown
                                                                    className="text-danger"
                                                                    size={18}
                                                                />
                                                            </span>
                                                            13.8%
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <CardComp
                            bodyText={
                                <div>
                                    <div className="mb-5">
                                        <TopNav
                                            TextComp={"Statistics"}
                                            DropDownText={
                                                <span>
                                                    <span className=""></span>
                                                    Month
                                                </span>
                                            }
                                        />
                                    </div>
                                    <div>
                                        <SimpleLineChart />
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    <div>
                        <div className="row ">
                            <div className="col-lg-8 ">
                                <div className="mb-4 ">
                                    <CardComp
                                        bodyText={
                                            <div>
                                                <div className="mb-3">
                                                    <TopNav
                                                        TextComp={
                                                            "Top Representatives"
                                                        }
                                                        DropDownText={
                                                            <span>
                                                                <span className=""></span>
                                                                Month
                                                            </span>
                                                        }
                                                    />
                                                </div>
                                                <div className="mb-5">
                                                    <TableComp
                                                        TableHeader={
                                                            refererHeaderData
                                                        }
                                                        TableBodyData={
                                                            topProductbodyData
                                                        }
                                                        data={mainreferenceData}
                                                    />
                                                </div>
                                                <div>
                                                    {" "}
                                                    <h6 className="text-center">
                                                        See More
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4  mb-4  ">
                                <CardComp
                                    bodyText={
                                        <div>
                                            <TopNav
                                                TextComp={"Top Areas"}
                                                DropDownText={
                                                    <span>
                                                        <span className=""></span>
                                                        Month
                                                    </span>
                                                }
                                            />
                                            {/* <div className="mb-3"><SVGOverlayExample/></div> */}
                                            <div className="mt-4">
                                                {/* <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <FiCircle
                                                            className="me-2"
                                                            color="#a3a1fb"
                                                        />{" "}
                                                        <p className="mb-0">
                                                            Lagos
                                                        </p>
                                                    </div>{" "}
                                                    <div>
                                                        <p className="mb-0">
                                                        NGN  {formatMoney(MapMertics?.lagos)}
                                                        </p>
                                                    </div>
                                                </div> */}
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <FiCircle
                                                            className="me-2"
                                                            color="#a3a1fb"
                                                        />{" "}
                                                        <p className="mb-0">
                                                            Agege
                                                        </p>
                                                    </div>{" "}
                                                    <div>
                                                        <p className="mb-0">
                                                            NGN 300,000
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <FiCircle
                                                            className="me-2"
                                                            color="#a3a1fb"
                                                        />{" "}
                                                        <p className="mb-0">
                                                            Apapa
                                                        </p>
                                                    </div>{" "}
                                                    <div>
                                                        <p className="mb-0">
                                                            NGN 300,000
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <FiCircle
                                                            className="me-2"
                                                            color="#a3a1fb"
                                                        />{" "}
                                                        <p className="mb-0">
                                                            Ikorodu
                                                        </p>
                                                    </div>{" "}
                                                    <div>
                                                        <p className="mb-0">
                                                            NGN 300,000
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <FiCircle
                                                            className="me-2"
                                                            color="#a3a1fb"
                                                        />{" "}
                                                        <p className="mb-0">
                                                            Lagos-Island
                                                        </p>
                                                    </div>{" "}
                                                    <div>
                                                        <p className="mb-0">
                                                            NGN 300,000
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row">
                            <div className="col-lg-8 mb-4 mb-md-0">
                                <CardComp
                                    bodyText={
                                        <>
                                            <div className="mb-3">
                                                <TopNav
                                                    TextComp={"Top Products"}
                                                    DropDownText={
                                                        <span>
                                                            <span className=""></span>
                                                            Month
                                                        </span>
                                                    }
                                                    // RightComp={<Link className="text-1 p" to='/product/all'>Show More</Link>}
                                                    // DropDownText="Show More"
                                                />
                                            </div>
                                            <div className="mb-5 Product">
                                                <TableComp
                                                    TableHeader={
                                                        topProductHeader
                                                    }
                                                    TableBodyData={bodyData}
                                                    data={
                                                        data?.products
                                                    }
                                                />
                                                <div className="text-center text-black">
                                                    <Link
                                                        to="/product/all"
                                                        className="text-black"
                                                    >
                                                        See More
                                                    </Link>
                                                </div>
                                            </div>
                                        </>
                                    }
                                />
                            </div>
                            <div className="col-lg-4 ">
                                <CardComp
                                    bodyText={
                                        <div>
                                            <TopNav
                                                TextComp={"Top Customers"}
                                                DropDownText={
                                                    <span>
                                                        <span className=""></span>
                                                        Month
                                                    </span>
                                                }
                                            />
                                            <hr />
                                            {/* <div className="mb-3"><SVGOverlayExample/></div> */}
                                            <div>
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="iconwrapper">
                                                            <RiUserAddLine
                                                                className=""
                                                                color="#8280ff"
                                                            />{" "}
                                                        </div>
                                                        <p
                                                            className="mb-0 text-muted"
                                                            style={{
                                                                color: "#4d4f5c"
                                                            }}
                                                        >
                                                            Last 24 Hours
                                                        </p>
                                                    </div>{" "}
                                                    <div>
                                                        <p className="mb-0">
                                                            290 new customers
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="iconwrapper">
                                                            <RiUserAddLine
                                                                className=""
                                                                color="#8280ff"
                                                            />{" "}
                                                        </div>
                                                        <p
                                                            className="mb-0 text-muted"
                                                            style={{
                                                                color: "#4d4f5c"
                                                            }}
                                                        >
                                                            Last 24 Hours
                                                        </p>
                                                    </div>{" "}
                                                    <div>
                                                        <p className="mb-0">
                                                            290 new customers
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="iconwrapper">
                                                            <RiUserAddLine
                                                                className=""
                                                                color="#8280ff"
                                                            />{" "}
                                                        </div>
                                                        <p
                                                            className="mb-0 text-muted"
                                                            style={{
                                                                color: "#4d4f5c"
                                                            }}
                                                        >
                                                            Last 24 Hours
                                                        </p>
                                                    </div>{" "}
                                                    <div>
                                                        <p className="mb-0">
                                                            290 new customers
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="iconwrapper">
                                                            <RiUserAddLine
                                                                className=""
                                                                color="#8280ff"
                                                            />{" "}
                                                        </div>
                                                        <p
                                                            className="mb-0 text-muted"
                                                            style={{
                                                                color: "#4d4f5c"
                                                            }}
                                                        >
                                                            Last 24 Hours
                                                        </p>
                                                    </div>{" "}
                                                    <div>
                                                        <p className="mb-0">
                                                            290 new customers
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="iconwrapper">
                                                            <RiUserAddLine
                                                                className=""
                                                                color="#8280ff"
                                                            />{" "}
                                                        </div>
                                                        <p
                                                            className="mb-0 text-muted"
                                                            style={{
                                                                color: "#4d4f5c"
                                                            }}
                                                        >
                                                            Last 24 Hours
                                                        </p>
                                                    </div>{" "}
                                                    <div>
                                                        <p className="mb-0">
                                                            290 new customers
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Style>
        </AppLayout>
    );
};

export default HomePage;
