import React, { useState } from "react";
import { BiBarcode } from "react-icons/bi";
import { BsBarChartFill } from "react-icons/bs";
import { HiOutlineUser } from "react-icons/hi";
import { IoIosHelpBuoy, IoIosSettings } from "react-icons/io";
import { RiFileList3Fill } from "react-icons/ri";
import { MdKeyboardArrowUp,MdKeyboardArrowDown } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Logo from "../../assets/images/logo-01.svg";

import { useAuth } from "../../hooks";
import { defaultLogo } from "../../utils/datautils";

const Style = styled.div`
img{
    width:100% !important;
}
`;
export const SideBar = () => {
    const [settings,setSetting]=useState(false)
    const { state, logout } = useAuth();
  
  
    return (
        <Style>
            <div className="mb-5 text-center me-5 me-md-0">
                <Link to="/">
                    <img alt="Logo" src={Logo} />
                </Link>
            </div>
            {state?.data?.supplierInformation && 
            <div className="mb-5 ">
            <img alt="Logo" style={{width:'100%'}} 
            src={state?.data?.supplierInformation?.length>0? state?.data?.supplierInformation[0]?.companyLogo:defaultLogo} />
            </div>
            }
            

            <div className="text-center">
            <Link to='/'>
                <div className="d-flex align-items-center pl-3 mb-5  text-muted">
                    <div className="col-md-3">
                        {" "}
                        <BsBarChartFill className="me-3 mb-0" />
                    </div>
                    <div>
                        {" "}
                        <h6 className="mb-0">DashBoard</h6>
                    </div>{" "}
                </div>
                </Link>
                <Link to='/product'>
                <div className="d-flex align-items-center  pl-3  text-muted   mb-5">
                  
                    <div className="col-md-3">
                        {" "}
                        <BiBarcode size={20} className="me-3" />
                    </div>
                    <div>
                        {" "}
                        <h6 className="mb-0 ">Product</h6>
                    </div>
                  
                </div>
                </Link>
                <Link to='/order/all'>
                <div className="d-flex align-items-center  pl-3  text-muted mb-5">
                    <div className="col-md-3">
                        {" "}
                        <RiFileList3Fill size={20} className="me-3" />
                    </div>
                    <div>
                        {" "}
                        <h6 className="mb-0 ">Orders</h6>
                    </div>
                </div>
                </Link>

                <Link to='/customer'>
                <div className="d-flex align-items-center  pl-3  text-muted mb-5">
                    <div className="col-md-3">
                        {" "}
                        <HiOutlineUser size={20} className="me-3" />
                    </div>
                    <div>
                        {" "}
                        <h6 className="mb-0 ">Customers</h6>
                    </div>
                </div>
                </Link>
                <Link to='/help-center'>
                <div className="d-flex align-items-center  pl-3  text-muted mb-5">
                <div className="col-md-3">
                        {" "}
                        <IoIosHelpBuoy size={20} className="me-3" />
                    </div>
                    <div>
                        {" "}
                        <h6 className="mb-0 ">Help Center </h6>
                    </div>
                </div>
                </Link>
               
                {/* <div className="d-flex align-items-center  pl-3  text-muted mb-5">
                    <div className="col-md-3">
                        {" "}
                        <IoIosSettings size={20} className="me-3" />
                    </div>
                    <div>
                        {" "}
                        <h6 className="mb-0 ">Settings</h6>
                    </div>
                </div> */}
                <div className=" mb-7" >
                <div
                
                className="d-flex align-items-center  pl-3  text-muted mb-3 ">
                  
                    <div className="col-md-3">
                        {" "}
                        <IoIosSettings size={20} className="me-3" />
                    </div>
                    <div onClick={()=>setSetting(!settings)}  className="d-flex justify-content-between w-100 video">
                        {" "}
                        <h6 className="mb-0 ">Settings </h6>
                        {!settings ? <MdKeyboardArrowUp/>: <MdKeyboardArrowDown/>}
                    </div>
                  
                </div>
                {settings && <>
                    <div className="text-start ps-4 ps-lg-5 mb-4"> 
                    <Link className="text-black text-muted " to={'/settings/myprofile'}><h6>Personal </h6></Link></div>
               <div className="text-start ps-4 ps-lg-5 mb-4"> 
               <Link className="text-black text-muted " to={'/settings/supplier'}><h6>Workspace </h6></Link></div>

                </>}
            
                </div>
                
                <div className="mb-5">
                    <button onClick={logout} className="border-0 px-3 py-2"><span className="me-3"><FiLogOut/></span>Log Out</button>
                </div>
            </div>
        </Style>
    );
};
