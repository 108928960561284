import React from 'react'
import { Form } from 'react-bootstrap'

export const  SelectComp =({className,selectOption,options,error,...props})=> {
  return (
    <div>
        <Form.Select aria-label="Default select example" className={className} {...props}>
  <option>
    {selectOption}
  </option>
  {options.map((item,index)=>
    <option value={item} key={index}>{item}</option>
  )}

</Form.Select>
{error && (
                <p
                    className="font-helvetica small px-3 mt-2 "
                    style={{ color: "red", fontWeight: 500 }}
                >
                    {error}
                </p>
            )}
    </div>
  )
}
