import React, { useEffect, useState } from "react";
import { AppLayout, Loading, TableComp, TopNav } from "../../components";
import moment from "moment";

import styled from "styled-components";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks";
import { GrView } from "react-icons/gr";

import {
    CustomerHeader,
    CustomerOrderHeader,
    ProductHeader
} from "../../utils/datautils";
import { Dropdown } from "react-bootstrap";
import { formatMoney } from "../../utils";

const Style = styled.div`
    .homepage {
        padding-top: 7rem;
    }
    .start-by-registering {
        padding: 14em 8em;
    }
    .card {
        border-radius: 15px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
        background-color: #fff;
    }
    .table {
        color: #4d4f5c;
    }
    .table thead th {
        font-size: 15px;
    }
    td {
        height: 40px;
    }
    .productWrapper {
        width: 55px;
    }
    .iconwrapper {
        width: 35px;
        height: 35px;
        border: 1px solid #e8e7ff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 2px;
        margin-right: 10px;
        background: #e8e7ff;
    }
    .dropdown-toggle::after {
        display: none !important;
    }
    @media (max-width: 767px) {
        .homepage {
            padding-top: 14rem;
        }
        .registering-cta {
            left: 0;
        }
    }
    @media (max-width: 480px) {
        .homepage {
            padding-top: 15rem;
        }
        .start-by-registering {
            padding: 12em 5em;
        }
    }
`;

export const Customer = () => {
    const [show, setShow] = useState(0);
    const [getCustomers, setGetCustomers] = useState(false);
    const [getCustomers1, setGetCustomers1] = useState(false);
    const [product, setProduct] = useState([]);
    const [data, setData] = useState("");
    const { state, getAllUserProductFunc, getAllUserOrderFunc } = useAuth();
    useEffect(() => {
        getAllUserProductFunc(state?.data?._id);
    }, [getAllUserProductFunc, state?.data?._id]);

    useEffect(() => {
        getAllUserOrderFunc();
    }, [getAllUserOrderFunc]);

    // console.log(state,'state')

    useEffect(() => {
        const newArray = [];
        state?.orders?.map((item) =>
            newArray.push({
                customer: item?.user?.customerInformation[0],
                order: item?.order,
                product: item?.products
            })
        );
        setGetCustomers(newArray);
    }, [state?.orders]);

    useEffect(() => {
        if (getCustomers) {
            const distinctValues = new Set();
            const withoutDuplicate = [];
            for (const tempObj of getCustomers) {
                // console.log(tempObj?.customer?.contactPersonName,'tempObj')
                if (!distinctValues.has(tempObj?.customer?.contactPersonName)) {
                    distinctValues.add(tempObj?.customer?.contactPersonName);
                    withoutDuplicate.push(tempObj);
                }
            }
            setGetCustomers1(withoutDuplicate);
        }
    }, [getCustomers]);

    const handleSort = (name) => {
        const list = state?.orders?.filter(
            (item) =>
                item?.user?.customerInformation[0]?.contactPersonName === name
            //console.log(item?.user?.customerInformation[0]?.contactPersonName,name)
        );
        const value = {
            name: list[0]?.user?.customerInformation[0],
            orders: list
        };
        // console.log(value, "list");

        setData(value);
    };

    const InvoiceBodyData = () => {
        if (getCustomers1.length > 0) {
            return getCustomers1?.map((item, index) => (
                <tr key={index} className="text-muted">
                    {/* <td><span>{index+1}</span></td> */}
                    <td>
                        <div className="row align-items-center">
                            <div className="col-sm-12 p">
                                <span>{index + 1}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="row align-items-center">
                            <div className="col-sm-12 p">
                                <span>{item?.customer?.businessName}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="row align-items-center">
                            <div className="col-sm-12">
                                <span>
                                    {moment(item?.order?.createdAt).format(
                                        "MMM Do YY"
                                    )}
                                </span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="row align-items-center">
                            <div className="col-sm-12">
                                <span>
                                    {item?.customer?.address.substring(0, 20) +
                                        "..."}
                                </span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="row align-items-center">
                            <div className="col-sm-12">
                                <span>{"active"}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="row align-items-center">
                            <div className="col-sm-12">
                                <span>{"-"}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="d-flex align-items-center h-100">
                            <button
                                className="btn"
                                onClick={() => {
                                    handleSort(
                                        item?.customer?.contactPersonName
                                    );
                                    setShow(1);
                                }}
                            >
                                <GrView /> View
                            </button>
                            {/* <Dropdown>
                                <Dropdown.Toggle className="dropdown-6 text-black border text-muted">
                                    ...
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {
                                            handleSort(
                                                item?.customer
                                                    ?.contactPersonName
                                            );
                                            setShow(1);
                                        }}
                                    >
                                        View Order
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> */}
                        </div>
                    </td>
                </tr>
            ));
        } else {
            return (
                <tr className="text-muted mb-7">
                    {/* <td><span>{index+1}</span></td> */}
                    <td>
                        <div className="row align-items-center">
                            <div className="col-sm-12 p">
                                <span>{"No Customer"}</span>
                            </div>
                        </div>
                    </td>
                </tr>
            );
        }
    };
    const CustomerOrder = () => {
        return data?.orders?.map((item, index) => (
            <tr key={index} className="text-muted">
                {/* <td><span>{index+1}</span></td> */}
                <td>
                    <div className="row align-items-center">
                        <div className="col-sm-12 p">
                            <span>{index + 1}</span>
                        </div>
                    </div>
                </td>

                <td>
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <span>
                                {moment(item?.order?.createdAt).format(
                                    "MMM Do YY"
                                )}
                            </span>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="row align-items-center">
                        <div className="col-sm-12 p">
                            <span>{item?.order?.trackingId}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <span>N{formatMoney(item?.order?.total)}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <span>{item?.status}</span>
                        </div>
                    </div>
                </td>
                {/* <td>
                <div className="row align-items-center">
                    <div className="col-sm-12">
                        <span>{"-"}</span>
                    </div>
                </div>
            </td> */}
                <td>
                    <div className="d-flex align-items-center h-100">
                        <Dropdown>
                            <Dropdown.Toggle className="dropdown-6 text-black border text-muted bg-6">
                                Action
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => {
                                        setShow(1);
                                        setProduct(item?.products);
                                        setShow(2);
                                        //    setDeleteProduct(true);
                                        //    setEditProduct(item);
                                        //    setEditShow(false);
                                    }}
                                >
                                    View Product
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => {
                                        //    setDeleteProduct(true);
                                        //    setEditProduct(item);
                                        //    setEditShow(false);
                                    }}
                                >
                                    Pending
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => {
                                        //    setDeleteProduct(true);
                                        //    setEditProduct(item);
                                        //    setEditShow(false);
                                    }}
                                >
                                    Delivered
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => {
                                        //    setDeleteProduct(true);
                                        //    setEditProduct(item);
                                        //    setEditShow(false);
                                    }}
                                >
                                    Processing
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => {
                                        //    setDeleteProduct(true);
                                        //    setEditProduct(item);
                                        //    setEditShow(false);
                                    }}
                                >
                                    Cancel
                                </Dropdown.Item>
                                {/* <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </td>
            </tr>
        ));
    };
    const ProductTable = () => {
        return product?.map((item, index) => (
            <tr key={index} className="text-muted">
                {/* <td><span>{index+1}</span></td> */}
                <td>
                    <div className="row align-items-center">
                        <div className="col-sm-12 p">
                            <span>{index + 1}</span>
                        </div>
                    </div>
                </td>

                <td>
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <span>
                                {/* {moment(item?.product?.productName).format(
                                    "MMM Do YY"
                                )} */}
                                {item?.product?.productName}
                            </span>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="row align-items-center">
                        <div className="col-sm-12 p">
                            <span>{item?.product?.productSku}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <span>{item?.product?.composition + "..."}</span>
                        </div>
                    </div>
                </td>
                <td>
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <span>N{formatMoney(item?.price)}</span>
                        </div>
                    </div>
                </td>
                {/* <td>
                <div className="row align-items-center">
                    <div className="col-sm-12">
                        <span>{"-"}</span>
                    </div>
                </div>
            </td> */}
                <td>
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <span>{item?.quantity}</span>
                        </div>
                    </div>
                </td>
            </tr>
        ));
    };
    return (
        <AppLayout mode="light">
            <Style>
                <div className="mb-3">
                    <TopNav
                        TextComp={<span className="color-1 h4">Customer</span>}
                        RightComp={
                            <div className="color-2">
                                {data && (
                                    <button
                                        className="btn me-2 bg bg-1 h6"
                                        onClick={() => {
                                            setData(null);
                                            setShow(0);
                                        }}
                                    >
                                        {"Back To Customers"}
                                    </button>
                                )}
                                <Link to="#" className="mr-2 btn bg-1 h6">
                                    Add Customer
                                </Link>
                            </div>
                        }
                    />
                </div>

                {show === 0 ? (
                    <div className="px-3 px-md-5 shadow bg-white pt-4 height-80">
                        {!state?.isLoading ? (
                            <TableComp
                                TableHeader={CustomerHeader}
                                TableBodyData={InvoiceBodyData}
                                loading={state?.isLoading}
                                data={getCustomers1}
                                emptyText={"No Customer Available"}
                            />
                        ) : (
                            <Loading height={"50vh"} />
                        )}
                    </div>
                ) : show === 1 && data ? (
                    <div className="px-3 px-md-5 shadow bg-white pt-4 height-80">
                        <TableComp
                            TableHeader={CustomerOrderHeader}
                            TableBodyData={CustomerOrder}
                            data={data?.orders}
                        />
                    </div>
                ) : (
                    <div className="px-3 px-md-5 shadow bg-white pt-4 height-80">
                        <TableComp
                            TableHeader={ProductHeader}
                            TableBodyData={ProductTable}
                            data={data?.orders}
                        />
                    </div>
                )}
            </Style>
        </AppLayout>
    );
};
