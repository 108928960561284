/* eslint-disable react/prop-types */
/* eslint-disable sort-keys */
/* eslint-disable react/display-name */
import Card from "@material-ui/core/Card";
import SortIcon from "@material-ui/icons/ArrowDownward";
import React from "react";
import DataTable from "react-data-table-component";

import styled from "styled-components";

//import movies from "./movies";

const Style = styled.div`
    width: 100%;

    .bIvCTc {
        margin-bottom: 15rem;
    }
    .dkBBnC,
    .jbSrtK {
        padding: 0 1rem;
    }
    .fhCFWO {
        color: #2e2c34 !important;
        font-weight: 700;
    }
    .rdt_TableHeadRow {
        font-size: 14px !important;
    }
`;

// // import "./styles.css";

// // const columns = [
// //     {
// //         id: 1,
// //         name: "Title",
// //         selector: (row) => row.title,

// //         sortable: true,
// //         reorder: true
// //     },
// //     {
// //         id: 2,
// //         name: "Director",
// //         selector: (row) => row.director,
// //         sortable: true,
// //         reorder: true
// //     },
// //     {
// //         id: 3,
// //         name: "Runtime (m)",
// //         selector: (row) => row.runtime,
// //         sortable: true,
// //         right: true,
// //         reorder: true
// //     },
// //     {
// //         id: 4,
// //         name: "Runtime (m)",
// //         cell: (row) => (
// //             <button onClick={() => {}} id={row.ID}>
// //                 Action
// //             </button>
// //         ),
// //         selector: (row) => row.runtime,
// //         sortable: true,
// //         right: true,
// //         reorder: true
// //     }
// // ];

export const TableCompData = ({ title, data, pagination, columns }) => {
    // console.log();
    return (
        <Style>
            <Card>
                <DataTable
                    responsive={true}
                    title={title}
                    columns={columns}
                    data={data}
                    // defaultSortFieldId={1}
                    sortIcon={<SortIcon />}
                    pagination={pagination ? true : false}

                    // selectableRows
                />
            </Card>
        </Style>
    );
};
