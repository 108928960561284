import React, { useEffect, useState } from "react";
import {
    AppLayout,
    Loading,
    ModalComp,
    TableComp,
    TopNav
} from "../../../components";
import { useQuery } from "react-query";
import moment from "moment";

import {
    InvoiceData,
    InvoiceHeader,
    OrderHeader
} from "../../../utils/datautils";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useAuth } from "../../../hooks";
import { RiDeleteBin6Line } from "react-icons/ri";
import { GrView } from "react-icons/gr";
import { getOrder } from "../../../services";

export const OrderShowAll = () => {
    const [show, setShow] = useState(false);
    const [orderData, setOrderData] = useState(false);
    const {
        state,
        getAllUserProductFunc,
        AcceptOrderFunc,
        RejectOrderFunc
    } = useAuth();

    const { data:dataOrders } = useQuery(
        "get-orders",
        getOrder,
        {
            // refetchOnWindowFocus: true,
            refetchInterval: 2000
            // refetchIntervalInBackground: true,
        }
    );
    //console.log(dataOrders,'dataOrders')
    
    const SortOrder = () => {
       
         const sortOrder=dataOrders?.order.sort((a, b) => 
                new Date( b?.order?.createdAt) - new Date(a?.order?.createdAt)
            );
            //  console.log(sortOrder, new Date("2015-03-25"),'c')
            return sortOrder
    }
    useEffect(() => {
        getAllUserProductFunc(state?.data?._id);
    }, [getAllUserProductFunc, state?.data?._id]);

    // useEffect(() => {
    //     getAllUserOrderFunc();
    // }, [getAllUserOrderFunc]);

    //  console.log(getProfileData,'helllo')
    const InvoiceBodyData = () => {
        if (orderData.products.length > 0) {
            return orderData.products?.map((item, index) => (
                <tr key={index} className="text-muted">
                    {/* <td><span>{index+1}</span></td> */}
                    <td>
                        <div className="row align-items-center">
                            <div className="col-sm-12 p">
                                <span>{item?.product?.productName}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="row align-items-center">
                            <div className="col-sm-12">
                                <span>{item?.quantity}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="row align-items-center">
                            <div className="col-sm-12">
                                <span className="me-3">{20}</span>
                                <span>
                                    <RiDeleteBin6Line size={20} />
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
            ));
        } else {
            return InvoiceData?.map((item, index) => (
                <tr key={index} className="text-muted mb-7">
                    {/* <td><span>{index+1}</span></td> */}
                    <td>
                        <div className="row align-items-center">
                            <div className="col-sm-12 p">
                                <span>{item?.product?.productName}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="row align-items-center">
                            <div className="col-sm-12">
                                <span>{item?.quantity}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="row align-items-center">
                            <div className="col-sm-12">
                                <span className="me-3">{20}</span>
                                <span>
                                    <RiDeleteBin6Line size={20} />
                                </span>
                            </div>
                        </div>
                    </td>
                </tr>
            ));
        }
    };
    const bodyData = () => {
        return SortOrder()?.map((item, index) => {
            return (
                <tr key={index}>
                    <td>
                        {/* { console.log(item,'getUserProductList')} */}
                        <span>{index + 1}</span>
                    </td>
                    <td>
                        <div className="row align-items-center">
                            <div className="col-sm-12 text-capitalize">
                                <span>{item?.user?.firstName} Pharmacy</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="row align-items-center">
                            <div className="col-sm-12">
                                <span>{item?.subOrderTrackingId}</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="d-flex align-items-center h-100">
                            {moment(item?.order?.createdAt).format("MMM Do YY")}
                        </span>
                    </td>
                    <td>
                        <span
                            className={` px-3 py-2 rounded text-white h-100 ${
                                item?.status === "ACCEPTED"
                                    ? "btn-success"
                                    : item?.status === "REJECT"
                                    ? "btn-danger "
                                    : "btn-warning"
                            }`}
                        >
                            {item?.status}
                        </span>
                    </td>
                    <td
                        onClick={() => {
                            setShow(true);
                            setOrderData(item);
                        }}
                    >
                        <span className="video">
                            <span>
                                <GrView />
                            </span>
                            View
                        </span>
                    </td>
                </tr>
            );
        });
    };

    return (
        <AppLayout mode="light">
            <Style>
                {/* {console.log(SortOrder())} */}
              
                <div className="mb-3">
                    <TopNav
                        TextComp={<h5 className="color-1 fw-2">Orders</h5>}
                        RightComp={
                            <div className="color-2">
                                {/* <button className="mr-2 bg bg-1 h6" >View All Products</button> */}
                                <Link to="#" className="mr-2 btn bg-1 h6">
                                    Add Invoices
                                </Link>
                            </div>
                        }
                    />
                </div>

                <div className="px-3 px-md-5 shadow bg-white pt-4 height-80">
                 
                    {SortOrder()?.length>0 ? (
                        <TableComp
                            TableHeader={OrderHeader}
                            TableBodyData={bodyData}
                            loading={state?.isLoading}
                            data={dataOrders?.order}
                            emptyText={"No Order Available"}
                            
                        />
                    ) : (
                        <Loading height={"50vh"} />
                    )}
                </div>
            </Style>
            <ModalComp
                    title={"Order List"}
                    show={show && orderData}
                    handleClose={setShow}
                    bodyText={
                        <div>
                            <div className="mb-4">
                                <div className="text-muted fw-1 p">
                                    <span className="me-3 text-capitalize">
                                        Name:
                                    </span>{" "}
                                    {orderData?.user?.firstName} Pharmacy
                                </div>
                                <div className="text-muted fw-1 p">
                                    <span className="me-3">Order ID: </span>{" "}
                                    {orderData?.subOrderTrackingId}
                                    {/* {console.log(orderData?.status,'orderData')} */}
                                </div>
                                <div className="text-muted fw-1 p">
                                    <span className="me-3">Order Date:</span>{" "}
                                    {moment(orderData?.order?.createdAt).format(
                                        "MMM Do YYYY"
                                    )}
                                </div>
                            </div>
                            <div className="px-4">
                                <TableComp
                                    TableHeader={InvoiceHeader}
                                    TableBodyData={InvoiceBodyData}
                                    loading={InvoiceBodyData}
                                    data={InvoiceData}
                                />
                            </div>
                            {orderData?.status !== "ACCEPTED" ? (
                                <div className="text-end">
                                    <button
                                        className="btn bg-8 me-4 px-4"
                                        onClick={() => {
                                            RejectOrderFunc(orderData?._id);
                                            setShow(false)
                                        }}
                                    >
                                        Reject
                                    </button>
                                    <button
                                        className="btn bg-6 text-white me-4 px-4"
                                        onClick={() => {
                                             AcceptOrderFunc(orderData?._id);
                                            // console.log(orderData,'orderData')
                                        }}
                                    >
                                        Accept
                                    </button>
                                </div>
                            ) : (
                                <div className="text-end">
                                    <Link
                                        to={`/invoice/${orderData?._id}`}
                                        className="px-4 btn bg-6 text-white"
                                    >
                                        View Invoice
                                    </Link>
                                </div>
                            )}
                        </div>
                    }
                />
        </AppLayout>
    );
};

const Style = styled.div`
    .homepage {
        padding-top: 7rem;
    }
    .start-by-registering {
        padding: 14em 8em;
    }
    .card {
        border-radius: 15px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.04);
        background-color: #fff;
    }
    .table {
        color: #4d4f5c;
    }
    .table thead th {
        font-size: 15px;
    }
    td {
        height: 40px;
    }
    .productWrapper {
        width: 55px;
    }
    .iconwrapper {
        width: 35px;
        height: 35px;
        border: 1px solid #e8e7ff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 2px;
        margin-right: 10px;
        background: #e8e7ff;
    }
    .dropdown-toggle::after {
        display: none !important;
    }
    @media (max-width: 767px) {
        .homepage {
            padding-top: 14rem;
        }
        .registering-cta {
            left: 0;
        }
    }
    @media (max-width: 480px) {
        .homepage {
            padding-top: 15rem;
        }
        .start-by-registering {
            padding: 12em 5em;
        }
    }
`;
