/* eslint-disable react/display-name */
/* eslint-disable sort-keys */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
//import { Form } from "react-bootstrap";

import {  Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Product1 from "../../../assets/images/client-logo-1.jpg";
import { AppLayout } from "../../../components";
// import DropDown from "../../../components/form/DropDown";
import { Loading } from "../../../components/Loading";
import { Back } from "../../../components/Parts";
import { useAuth } from "../../../hooks";
import { formatMoney } from "../../../utils";

const Styled  = styled.div`
.allproduct{
    font-size: 20px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8b8a8a;
}
select,label{
    box-shadow: none !important;
    color:#8b8a8a !important;
    
}
label{
    font-weight:600;
}
.btn-new{
    border-radius: 24px;
    background-color: #463c74;
    color:#fff;
    font-size: 12px;
}
.product-filter button{
    border-radius: 20px !important;
}


`;


export const SupplierProduct = () => {
    const params = useParams();
    const  { supplier_id } =params;
    const navigate = useNavigate();
    const { supplierProduct,supplierProductFunc,state:{ data } } = useAuth();
    //console.log(data?.hasSubscribed,"supplierProduct");
    
    const handleViewProduct = (id) => {
        
        navigate(`/products/${id}`);
    };

    // const columns = [
    //     // {
    //     //     id: 1,
    //     //     name: "#",
    //     //     selector: (row) => row.index,
    
    //     //     sortable: true,
    //     //     reorder: true
    //     // },
    //     {
    //         id: 2,
    //         name: "Product Name",
    //         className:"nna",
    //         selector: (row) => row.productName,
    
    //         sortable: true,
    //         reorder: true
    //     },
    //     {
    //         id: 3,
    //         name: "SKU",
    //         selector: (row) => row.productSKU,
    //         sortable: true,
    //         reorder: true
    //     },
       
    //     {
    //         id: 3,
    //         name: "PRICE",
    //         selector: (row) => row.costPerUnit,
    //         sortable: true,
    //         right: true,
    //         reorder: true
    //     },
    //     {
    //         id: 4,
    //         name: "Runtime (m)",
    //         cell: (row) => (
               
    //             <button className="btn btn-new px-4" onClick={() => handleViewProduct(row?._id)} id={row.ID}>
    //                 Action
                   
    //             </button>
    //         ),
    //         selector: (row) => row.runtime,
    //         sortable: true,
    //         right: true,
    //         reorder: true
    //     }
    // ];
    
    
    useEffect(() => {
        if( supplier_id)supplierProductFunc( supplier_id);
    }, [supplierProductFunc,  supplier_id]);

    // if(!supplierProduct.suppilersdetails?.id) navigate("/");
    if(supplierProduct.isLoading) return <div><Loading/></div>;
    if(supplierProduct.products?.length>0)
    
        return (
            <AppLayout mode="light"  
            >
                <Styled>
                    <div className="main-content pt-2 pt-md-2 pb-2 pb-md-5 mb-2 mb-md-5">
                        <div className="container">
                            <div className="mb-3 d-flex align-items-center">
                                <Back/>
                           
                                <span className="ml-5"><h6 className="mb-0">home / {supplierProduct?.suppilersdetails?.supplierName}</h6></span>
                            </div>
                            <div className="row my-auto">
                                <div className="col-12 col-md-4 h-100">
                                    <div
                                        className="client-sidebar p-4"
                                        style={{
                                            borderRadius: "20px"
                                        }}
                                    >
                                        <div
                                            className="card mb-5 border-0"
                                            style={{
                                                background: "transparent"
                                            }}
                                        >
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-3 p-1">
                                                        <div className="product-logo">
                                                            <img
                                                                className="rounded"
                                                                src={Product1}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-9 p-1">
                                                        {/* <h5 className="skeleton skeleton-text">{}</h5> */}
                                                        <h5 className="text-capitalize">{supplierProduct?.suppilersdetails?.supplierName}</h5>
                                                        <h6>Wilson Kisanya</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div className="product-filter mb-5">
                                            <Link
                                                type="button"
                                                to="/products/list"
                                                className="btn btn-light d-block w-100 mb-3 py-2 rounded"
                                            >
                                                
                                                All products
                                            </Link>
    
                                            <button
                                                type="button"
                                                className="btn btn-light d-block w-100 mb-3 py-2"
                                            >
                                                Recent Uploads
                                            </button>
    
                                            <button
                                                type="button"
                                                className="btn btn-light d-block w-100 mb-3 py-2"
                                            >
                                                Download List
                                            </button>
                                        </div>
                                        {!data?.hasSubscribed &&  <div
                                            className="client-pricing text-center py-4 px-4 w-100"
                                            style={{
                                                backgroundColor: "#463c74",
                                                color: "#ffffff"
                                            }}
                                        >
                                            <h5 className="text-white text-capitalize mb-3">
                                                upgrade to PRO
                                            </h5>
                                            <h6 className="text-white mb-3">
                                                unlock more features and unlimited price
                                                lists
                                            </h6>
    
                                            <span className="d-inline-block">
                                                <a
                                                    href=""
                                                    className="btn btn-warning btn-lg text-uppercase"
                                                    role="button"
                                                    aria-pressed="true"
                                                    style={{
                                                        fontSize: "14px",
                                                        borderRadius: "20px",
                                                        padding: "10px 20px"
                                                    }}
                                                >
                                                    Upgrade Account
                                                </a>
                                            </span>
                                        </div>}
                                       
                                    </div>
                                </div>
    
                                <div className="col-12 col-md-8">
                                    <div className="client-data-table">
                                        <div className="container-fluid">
                                            <div className="search-wrapper w-100 mt-3 mt-md-0 mb-4">
                                                <div className="search_box border">
                                                    <div className="search_field position-relative">
                                                        <i className="bi bi-search supplier-icon"></i>
                                                        <input
                                                            type="text"
                                                            className="input"
                                                            placeholder="Search"
                                                        />
                                                        <i className="fas fa-search"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            
    
                                            <div className="data-table table-responsive mb-5">
                                                {/* <Table pagination columns={columns} data={supplierProduct?.products}/> */}
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">
                                                                Product Name
                                                            </th>
                                                            <th scope="col">SKU</th>
                                                            <th scope="col">Prices</th>
                                                            <th
                                                                scope="col"
                                                                className="text-center"
                                                            >
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {supplierProduct?.products.map((item,index)=><tr key={index}>
                                                            <th scope="row">{index+1}</th>
                                                            <td className="text-capitalize">
                                                                {item?.productName}
                                                            </td>
                                                            <td>{item?.productSKU}</td>
                                                            <td>NGN {formatMoney(item?.costPerUnit)}</td>
                                                            <td className="text-center">
                                                                <button className="btn btn-new px-4" onClick={()=>handleViewProduct(item?._id)}  >View</button>
                                                               
                                                            </td>
                                                        </tr>)}
                                                    
                                                  
                                                    </tbody>
                                                </table>
                                            </div>
    
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Styled>
            </AppLayout>
        );
    
    

};
